import websitePortfolioPic from './website_portfolio.png'
import onboardingPic from './onboarding.png'

const PORTFOLIO_IMAGES = {
  logo: websitePortfolioPic,
  images: [
    onboardingPic,
  ],
}

export default PORTFOLIO_IMAGES 
