import studyBashLogo from './study_bash.png'

const FUEL_CONSOLIDATOR_RESOURCES = {
  logo: studyBashLogo,
  yt_video_ids: [
    'jbFKi2_hnpc', // hw1
  ],
}

export default FUEL_CONSOLIDATOR_RESOURCES
